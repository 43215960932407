@import "./variables.css";
@import "~react-datepicker/dist/react-datepicker.min.css";
@import "~react-image-gallery/styles/css/image-gallery.css";
@import "~react-multi-carousel/lib/styles.css";
/*@import "~react-select-plus/dist/react-select-plus.css";*/

.icon-spin {
    animation: icon-spin 2s infinite linear;
}
@keyframes icon-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

.hotel-datepicker-popper {
    z-index: 9999;
}

.modal {
    z-index: 10001;
}

/*TABS BAR*/

.searchHome .flightSearch {
    border-top-left-radius: 0 !important;
}

/*Global classes for site*/

.quote {
    font-size: 40px;
    margin-left: 0;
    margin-top: 50px;
    height: 100%;
    width: 100%;
}

#loader {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.41);
    z-index: 999998;
}
#spinner {
    -webkit-transform: rotate(30deg);
    -moz-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    -o-transform: rotate(30deg);
    transform: rotate(30deg);
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #3498db;
    border-right: 8px solid #3498db;
    width: 120px;
    height: 120px;
    position: fixed;
    z-index: 999999;
    left: 50%;
    top: 50%;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    margin-left: -60px;
    margin-top: -60px;
}

#loader-img {
    background: url(../../../public/images/airplane.svg) no-repeat;
    background-size: 60px 60px;
    background-position: center;
    width: 120px;
    height: 120px;
    position: fixed;
    z-index: 999999;
    left: 50%;
    top: 50%;
    margin-left: -60px;
    margin-top: -60px;
}

.progress-bar {
    height: 3px;
    background-color: rgb(13, 132, 201);
    position: fixed;
    z-index: 10000000;
}

.odz_toggle_text img {
    width: 400px;
    height: 200px;
}

/*Hamburger */
.fil0 {
    fill: #0060aa;
}
.fil1 {
    fill: #0060aa;
    fill-rule: nonzero;
}

.hamburger {
    padding: 15px 15px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
}
.hamburger:hover {
    opacity: 0.7;
}

.hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: #000;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
    content: "";
    display: block;
}
.hamburger-inner::before {
    top: -10px;
}
.hamburger-inner::after {
    bottom: -10px;
}

/*
       * Squeeze
       */
.hamburger--squeeze .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}
.hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease,
        transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}
.hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease,
        transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Base styles
 * ========================================================================== */
#app {
    min-height: 100vh; /* will cover the 100% of viewport */
    overflow: hidden;
    display: block;
    position: relative;
    padding-bottom: 100px;
}

html,
body {
    height: 100%;
    position: relative;
}

html {
    color: #222;
    font-weight: 100;
    font-size: 1em;
    font-family: "Open sans", sans-serif;
    line-height: 1.375;
}

body {
    color: #131c33;
    font-family: "Open sans", sans-serif;
    font-size: 14px;
    background-color: #fff;
    line-height: 1.42857143;
}

a {
    text-decoration: none;
    color: #002568;
}

a:hover {
    color: #e63451;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #002568;
}

h1 {
    font-size: 35px;
    font-weight: 700;
    margin: 10px 0 25px;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

label {
    font-weight: 500;
}

p {
    margin: 0 0 10px;
}

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

/*
 * A better looking default horizontal rule
 */

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
    resize: vertical;
}

/*
 * Browser upgrade prompt
 * ========================================================================== */

:global(.browserupgrade) {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

/*
 * Print styles
 * Inlined to avoid the additional HTTP request:
 * http://www.phpied.com/delay-loading-your-print-css/
 * ========================================================================== */

@media print {
    *,
    *::before,
    *::after {
        background: transparent !important;
        color: #000 !important; /* Black prints faster: http://www.sanbeiji.com/archives/953 */
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]::after {
        content: " (" attr(href) ")";
    }

    abbr[title]::after {
        content: " (" attr(title) ")";
    }

    /*
   * Don't show links that are fragment identifiers,
   * or use the `javascript:` pseudo protocol
   */

    a[href^="#"]::after,
    a[href^="javascript:"]::after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    /*
   * Printing Tables:
   * http://css-discuss.incutio.com/wiki/Printing_Tables
   */

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}
